const tarifCards = document.querySelectorAll('.tarif-card');
tarifCards.forEach((tarifCard) => {
  const action = tarifCard.querySelector('.tarif-card__action');
  const toggle = tarifCard.querySelector('.tarif-card__toggle');

  if (action && toggle) {
    document.addEventListener('click', (evt) => {
      if (evt.target === toggle) {
        action.classList.toggle('tarif-card__action--active');
      } else {
        action.classList.remove('tarif-card__action--active');
      }
    });
  }
});
